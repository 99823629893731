import React from 'react';
// import PropTypes from 'prop-types';
// import Notification  from 'cccisd-notification';
import Form from './form.js';
import Loader from 'cccisd-loader';
import Axios from 'cccisd-axios';
import Notification from 'cccisd-notification';
const Boilerplate = window.cccisd.boilerplate;

export default class Component extends React.Component {
    static propTypes = {
        // params: PropTypes.object,
    };

    state = {
        loading: false,
        data: null,
    };

    componentDidMount = async () => {
        // getDataFrom Query
        // passData down to Form
        const response = await Axios.get(Boilerplate.route('app.get.data'));

        if (response) {
            const CARES = response.data.data.CARES;

            this.setState({ data: CARES ? CARES.data : [] });
        }
    };

    onSubmit = async values => {
        let arr = Object.keys(values).map((key, i) => {
            return { name: key, value: values[key] };
        });

        const CARESData = this.extractData(arr);

        const response = await Axios.post(Boilerplate.route('app.set.data'), {
            data_key: 'CARES',
            data: CARESData,
        });

        if (response.data.success) {
            Notification({
                message: 'CARES data saved.',
                duration: 3000,
            });
        }
    };

    extractData = arr => {
        const connectionData = arr.filter(item => item.name.includes('connection'));
        const connection = this.processData(connectionData);

        const authenticRelationshipsData = arr.filter(item =>
            item.name.includes('authenticRelationships')
        );
        const authenticRelationships = this.processData(authenticRelationshipsData);
        const reflectiveThinkingData = arr.filter(item => item.name.includes('reflectiveThinking'));
        const reflectiveThinking = this.processData(reflectiveThinkingData);
        const effectiveCommunicationData = arr.filter(item =>
            item.name.includes('effectiveCommunication')
        );
        const effectiveCommunication = this.processData(effectiveCommunicationData);
        const sensitivityData = arr.filter(item => item.name.includes('sensitivity'));
        const sensitivity = this.processData(sensitivityData);

        return [
            { connection },
            { authenticRelationships },
            { reflectiveThinking },
            { effectiveCommunication },
            { sensitivity },
        ];
    };

    processData = array => {
        const mappedData = array.map(item => {
            if (item.value) {
                const cleanValue = item.value.replace(/^\s*\n/gm, '');

                return cleanValue.split('\n').filter(value => value !== '');
            }
            return [];
        });

        return mappedData;
    };

    render() {
        return (
            <Loader loading={this.state.loading} removeChildren>
                {this.state.data && <Form onSubmit={this.onSubmit} data={this.state.data} />}
            </Loader>
        );
    }
}
