import React from 'react';
import { Formik, Form, Field, CccisdTextarea } from 'cccisd-formik';
import PropTypes from 'prop-types';
import style from './style.css';
import Save from 'cccisd-icons/folder-upload2';
import Triangle from 'cccisd-icons/triangle2';
export default class Component extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        data: PropTypes.object,
    };

    state = {
        data: this.props.data.length > 0 ? JSON.parse(this.props.data) : [],
        show: {
            connection: false,
            authenticRelationships: false,
            reflectiveThinking: false,
            effectiveCommunication: false,
            sensitivity: false,
        },
    };

    getInitialValues = (question, index) => {
        if (this.state.data) {
            const data = this.state.data;
            const entry = data.find(item => {
                if (question in item) {
                    return item;
                }
                return null;
            });
            if (entry) {
                const values = entry[question];
                if (values[index].length > 0) {
                    let string = '';
                    values[index].forEach(item => {
                        string = string + item + '\n';
                    });

                    return string;
                }
            }
            return '';
        }
    };

    render() {
        return (
            <div className={style.classroomStructure}>
                <div>
                    Enter and edit the list of descriptors for each score for each scale item.
                    Separate descriptors by using a new line.
                </div>
                <br />
                <Formik
                    initialValues={{
                        connection0: this.getInitialValues('connection', 0),
                        connection1: this.getInitialValues('connection', 1),
                        connection2: this.getInitialValues('connection', 2),
                        connection3: this.getInitialValues('connection', 3),
                        connection4: this.getInitialValues('connection', 4),
                        connection5: this.getInitialValues('connection', 5),
                        authenticRelationships0: this.getInitialValues('authenticRelationships', 0),
                        authenticRelationships1: this.getInitialValues('authenticRelationships', 1),
                        authenticRelationships2: this.getInitialValues('authenticRelationships', 2),
                        authenticRelationships3: this.getInitialValues('authenticRelationships', 3),
                        authenticRelationships4: this.getInitialValues('authenticRelationships', 4),
                        authenticRelationships5: this.getInitialValues('authenticRelationships', 5),
                        reflectiveThinking0: this.getInitialValues('reflectiveThinking', 0),
                        reflectiveThinking1: this.getInitialValues('reflectiveThinking', 1),
                        reflectiveThinking2: this.getInitialValues('reflectiveThinking', 2),
                        reflectiveThinking3: this.getInitialValues('reflectiveThinking', 3),
                        reflectiveThinking4: this.getInitialValues('reflectiveThinking', 4),
                        reflectiveThinking5: this.getInitialValues('reflectiveThinking', 5),
                        effectiveCommunication0: this.getInitialValues('effectiveCommunication', 0),
                        effectiveCommunication1: this.getInitialValues('effectiveCommunication', 1),
                        effectiveCommunication2: this.getInitialValues('effectiveCommunication', 2),
                        effectiveCommunication3: this.getInitialValues('effectiveCommunication', 3),
                        effectiveCommunication4: this.getInitialValues('effectiveCommunication', 4),
                        effectiveCommunication5: this.getInitialValues('effectiveCommunication', 5),
                        sensitivity0: this.getInitialValues('sensitivity', 0),
                        sensitivity1: this.getInitialValues('sensitivity', 1),
                        sensitivity2: this.getInitialValues('sensitivity', 2),
                        sensitivity3: this.getInitialValues('sensitivity', 3),
                        sensitivity4: this.getInitialValues('sensitivity', 4),
                        sensitivity5: this.getInitialValues('sensitivity', 5),
                    }}
                    onSubmit={this.props.onSubmit}
                    render={() => (
                        <Form>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                connection: !this.state.show.connection,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Connection to the Curriculum</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.connection ? style.open : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.connection && (
                                    <div>
                                        <Field
                                            name="connection0"
                                            component={CccisdTextarea}
                                            label="Score: 0 (Needs Attention)"
                                        />
                                        <Field
                                            name="connection1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="connection2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="connection3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="connection4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="connection5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                authenticRelationships: !this.state.show
                                                    .authenticRelationships,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Authentic Relationships</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.authenticRelationships
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.authenticRelationships && (
                                    <div>
                                        <Field
                                            name="authenticRelationships0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="authenticRelationships1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="authenticRelationships2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="authenticRelationships3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="authenticRelationships4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="authenticRelationships5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                reflectiveThinking: !this.state.show
                                                    .reflectiveThinking,
                                            },
                                        });
                                    }}
                                >
                                    <h4>
                                        Reflective Thinking about Cultural, Racial/Ethnic, and Class
                                        Differences
                                    </h4>{' '}
                                    <span
                                        className={
                                            this.state.show.reflectiveThinking
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.reflectiveThinking && (
                                    <div>
                                        <Field
                                            name="reflectiveThinking0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="reflectiveThinking1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="reflectiveThinking2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="reflectiveThinking3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="reflectiveThinking4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="reflectiveThinking5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                effectiveCommunication: !this.state.show
                                                    .effectiveCommunication,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Effective Communication</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.effectiveCommunication
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.effectiveCommunication && (
                                    <div>
                                        <Field
                                            name="effectiveCommunication0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="effectiveCommunication1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="effectiveCommunication2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="effectiveCommunication3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="effectiveCommunication4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="effectiveCommunication5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                sensitivity: !this.state.show.sensitivity,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Sensitivity</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.sensitivity ? style.open : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.sensitivity && (
                                    <div>
                                        <Field
                                            name="sensitivity0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="sensitivity1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="sensitivity2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="sensitivity3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="sensitivity4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="sensitivity5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>

                            <button
                                className={`btn btn-primary ${style.stickyButton}`}
                                type="submit"
                            >
                                <span style={{ marginRight: '5px' }}>
                                    <Save />
                                </span>
                                Save Values
                            </button>
                        </Form>
                    )}
                />
            </div>
        );
    }
}
