import React from 'react';
import { Formik, Form, Field, CccisdDatepicker } from 'cccisd-formik';
import PropTypes from 'prop-types';
import style from './style.css';
import Save from 'cccisd-icons/folder-upload2';

export default class Component extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        data: PropTypes.object,
    };

    getInitialValues = question => {
        if (this.props.data) {
            const data = this.props.data;

            return data[question];
        }
        return null;
    };

    render() {
        return (
            <div className={style.dates}>
                <div>Enter and edit the dates that control when automatic messages are sent.</div>
                <br />
                <Formik
                    initialValues={{
                        startDate: this.getInitialValues('startDate'),
                        endDate: this.getInitialValues('endDate'),
                        breakStop: this.getInitialValues('breakStop'),
                        breakResume: this.getInitialValues('breakResume'),
                    }}
                    onSubmit={this.props.onSubmit}
                    render={() => (
                        <Form>
                            <div className={style.formWrapper}>
                                <div className={style.formRow}>
                                    <Field
                                        name="startDate"
                                        component={CccisdDatepicker}
                                        label="Start Date"
                                    />
                                    <Field
                                        name="endDate"
                                        component={CccisdDatepicker}
                                        label="End Date"
                                    />
                                </div>
                                <div className={style.formRow}>
                                    <Field
                                        name="breakStop"
                                        component={CccisdDatepicker}
                                        label="Winter Break Stop Date"
                                    />
                                    <Field
                                        name="breakResume"
                                        component={CccisdDatepicker}
                                        label="Winter Break Resume Date"
                                    />
                                </div>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <button className="btn btn-primary" type="submit">
                                    <span style={{ marginRight: '5px' }}>
                                        <Save />
                                    </span>
                                    Save Values
                                </button>
                            </div>
                        </Form>
                    )}
                />
            </div>
        );
    }
}
