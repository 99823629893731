import React from 'react';
// import PropTypes from 'prop-types';
import Form from './form.js';
import Loader from 'cccisd-loader';
import Axios from 'cccisd-axios';
import Notification from 'cccisd-notification';
const Boilerplate = window.cccisd.boilerplate;

export default class Component extends React.Component {
    static propTypes = {
        // params: PropTypes.object,
    };

    state = {
        loading: false,
    };

    componentDidMount = async () => {
        // getDataFrom Query
        // passData down to Form
        const response = await Axios.get(Boilerplate.route('app.get.data'));

        if (response) {
            const PBSCC = response.data.data.PBSCC;

            this.setState({ data: PBSCC ? PBSCC.data : [] });
        }
    };

    onSubmit = async values => {
        let arr = Object.keys(values).map((key, i) => {
            return { name: key, value: values[key] };
        });

        const PBSCCData = this.extractData(arr);

        const response = await Axios.post(Boilerplate.route('app.set.data'), {
            data_key: 'PBSCC',
            data: PBSCCData,
        });

        if (response.data.success) {
            Notification({
                message: 'PBS & CC data saved.',
                duration: 3000,
            });
        }
    };

    extractData = arr => {
        const studentEngagementData = arr.filter(item => item.name.includes('studentEngagement'));
        const studentEngagement = this.processData(studentEngagementData);
        const disruptiveBehaviorData = arr.filter(item => item.name.includes('disruptiveBehavior'));
        const disruptiveBehavior = this.processData(disruptiveBehaviorData);
        const pacingInstructionData = arr.filter(item => item.name.includes('pacingInstruction'));
        const pacingInstruction = this.processData(pacingInstructionData);
        const clearExpectationsData = arr.filter(item => item.name.includes('clearExpectations'));
        const clearExpectations = this.processData(clearExpectationsData);
        const praiseUseData = arr.filter(item => item.name.includes('praiseUse'));
        const praiseUse = this.processData(praiseUseData);
        const reprimandsUseData = arr.filter(item => item.name.includes('reprimandsUse'));
        const reprimandsUse = this.processData(reprimandsUseData);
        const smoothTransitionsData = arr.filter(item => item.name.includes('smoothTransitions'));
        const smoothTransitions = this.processData(smoothTransitionsData);
        const otherData = arr.filter(item => item.name.includes('other'));
        const other = this.processData(otherData);

        return [
            { studentEngagement },
            { disruptiveBehavior },
            { pacingInstruction },
            { clearExpectations },
            { praiseUse },
            { reprimandsUse },
            { smoothTransitions },
            { other },
        ];
    };

    processData = array => {
        const mappedData = array.map(item => {
            if (item.value) {
                const cleanValue = item.value.replace(/^\s*\n/gm, '');

                return cleanValue.split('\n').filter(value => value !== '');
            }
            return [];
        });
        return mappedData;
    };

    render() {
        return (
            <Loader loading={this.state.loading} removeChildren>
                {this.state.data && <Form onSubmit={this.onSubmit} data={this.state.data} />}
            </Loader>
        );
    }
}
