import React from 'react';
import { Formik, Form, Field, CccisdTextarea } from 'cccisd-formik';
import PropTypes from 'prop-types';
import style from './style.css';
import Save from 'cccisd-icons/folder-upload2';
export default class Component extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        data: PropTypes.object,
    };

    state = {
        data: this.props.data.length > 0 ? JSON.parse(this.props.data) : [],
        // data: this.props.data.length > 0 ? JSON.parse(this.props.data) : [],
    };

    componentDidMount = () => {};

    getInitialValues = () => {
        // replace commas with line breaks for easier reading
        return this.state.data.replace(/,/g, ',\n');
    };

    render() {
        return (
            <div className={style.classroomStructure}>
                <h4>
                    Enter the order of the teacher nudges in a <strong>comma separated</strong>{' '}
                    list.
                </h4>
                <br />
                <Formik
                    initialValues={{
                        // teacherNudges: this.state.data,
                        teacherNudges: this.getInitialValues('teacherNudges', ''),
                    }}
                    onSubmit={this.props.onSubmit}
                    render={() => (
                        <Form>
                            <div>
                                <Field
                                    name="teacherNudges"
                                    component={CccisdTextarea}
                                    label="Order of Nudges"
                                    type="textarea"
                                    rows="30"
                                />
                            </div>

                            <button
                                className={`btn btn-primary ${style.stickyButton}`}
                                type="submit"
                            >
                                <span style={{ marginRight: '5px' }}>
                                    <Save />
                                </span>
                                Save Values
                            </button>
                        </Form>
                    )}
                />
            </div>
        );
    }
}
