import React from 'react';
import { Formik, Form, Field, CccisdTextarea } from 'cccisd-formik';
import PropTypes from 'prop-types';
import style from './style.css';
import Save from 'cccisd-icons/folder-upload2';
import Triangle from 'cccisd-icons/triangle2';
export default class Component extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        data: PropTypes.object,
    };

    state = {
        data: this.props.data.length > 0 ? JSON.parse(this.props.data) : [],
        show: {
            studentEngagement: false,
            disruptiveBehavior: false,
            pacingInstruction: false,
            clearExpectations: false,
            praiseUse: false,
            reprimandsUse: false,
            smoothTransitions: false,
            other: false,
        },
    };

    getInitialValues = (question, index) => {
        if (this.state.data) {
            const data = this.state.data;
            const entry = data.find(item => {
                if (question in item) {
                    return item;
                }
                return null;
            });
            if (entry) {
                const values = entry[question];
                if (values[index].length > 0) {
                    let string = '';
                    values[index].forEach(item => {
                        string = string + item + '\n';
                    });

                    return string;
                }
            }
            return '';
        }
    };

    render() {
        return (
            <div>
                <div>
                    Enter and edit the list of descriptors for each score for each scale item.
                    Separate descriptors by using a new line.
                </div>
                <br />
                <Formik
                    initialValues={{
                        studentEngagement0: this.getInitialValues('studentEngagement', 0),
                        studentEngagement1: this.getInitialValues('studentEngagement', 1),
                        studentEngagement2: this.getInitialValues('studentEngagement', 2),
                        studentEngagement3: this.getInitialValues('studentEngagement', 3),
                        studentEngagement4: this.getInitialValues('studentEngagement', 4),
                        studentEngagement5: this.getInitialValues('studentEngagement', 5),
                        disruptiveBehavior0: this.getInitialValues('disruptiveBehavior', 0),
                        disruptiveBehavior1: this.getInitialValues('disruptiveBehavior', 1),
                        disruptiveBehavior2: this.getInitialValues('disruptiveBehavior', 2),
                        disruptiveBehavior3: this.getInitialValues('disruptiveBehavior', 3),
                        disruptiveBehavior4: this.getInitialValues('disruptiveBehavior', 4),
                        disruptiveBehavior5: this.getInitialValues('disruptiveBehavior', 5),
                        pacingInstruction0: this.getInitialValues('pacingInstruction', 0),
                        pacingInstruction1: this.getInitialValues('pacingInstruction', 1),
                        pacingInstruction2: this.getInitialValues('pacingInstruction', 2),
                        pacingInstruction3: this.getInitialValues('pacingInstruction', 3),
                        pacingInstruction4: this.getInitialValues('pacingInstruction', 4),
                        pacingInstruction5: this.getInitialValues('pacingInstruction', 5),
                        clearExpectations0: this.getInitialValues('clearExpectations', 0),
                        clearExpectations1: this.getInitialValues('clearExpectations', 1),
                        clearExpectations2: this.getInitialValues('clearExpectations', 2),
                        clearExpectations3: this.getInitialValues('clearExpectations', 3),
                        clearExpectations4: this.getInitialValues('clearExpectations', 4),
                        clearExpectations5: this.getInitialValues('clearExpectations', 5),
                        praiseUse0: this.getInitialValues('praiseUse', 0),
                        praiseUse1: this.getInitialValues('praiseUse', 1),
                        praiseUse2: this.getInitialValues('praiseUse', 2),
                        praiseUse3: this.getInitialValues('praiseUse', 3),
                        praiseUse4: this.getInitialValues('praiseUse', 4),
                        praiseUse5: this.getInitialValues('praiseUse', 5),
                        reprimandsUse0: this.getInitialValues('reprimandsUse', 0),
                        reprimandsUse1: this.getInitialValues('reprimandsUse', 1),
                        reprimandsUse2: this.getInitialValues('reprimandsUse', 2),
                        reprimandsUse3: this.getInitialValues('reprimandsUse', 3),
                        reprimandsUse4: this.getInitialValues('reprimandsUse', 4),
                        reprimandsUse5: this.getInitialValues('reprimandsUse', 5),
                        smoothTransitions0: this.getInitialValues('smoothTransitions', 0),
                        smoothTransitions1: this.getInitialValues('smoothTransitions', 1),
                        smoothTransitions2: this.getInitialValues('smoothTransitions', 2),
                        smoothTransitions3: this.getInitialValues('smoothTransitions', 3),
                        smoothTransitions4: this.getInitialValues('smoothTransitions', 4),
                        smoothTransitions5: this.getInitialValues('smoothTransitions', 5),
                        other0: this.getInitialValues('other', 0),
                        other1: this.getInitialValues('other', 1),
                        other2: this.getInitialValues('other', 2),
                        other3: this.getInitialValues('other', 3),
                        other4: this.getInitialValues('other', 4),
                        other5: this.getInitialValues('other', 5),
                    }}
                    onSubmit={this.props.onSubmit}
                    render={() => (
                        <Form>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                studentEngagement: !this.state.show
                                                    .studentEngagement,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Student Engagement</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.studentEngagement
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.studentEngagement && (
                                    <div>
                                        <Field
                                            name="studentEngagement0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="studentEngagement1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="studentEngagement2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="studentEngagement3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="studentEngagement4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="studentEngagement5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                disruptiveBehavior: !this.state.show
                                                    .disruptiveBehavior,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Level of Disruptive Behavior</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.disruptiveBehavior
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.disruptiveBehavior && (
                                    <div>
                                        <Field
                                            name="disruptiveBehavior0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="disruptiveBehavior1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="disruptiveBehavior2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="disruptiveBehavior3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="disruptiveBehavior4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="disruptiveBehavior5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                pacingInstruction: !this.state.show
                                                    .pacingInstruction,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Pacing of Instruction</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.pacingInstruction
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.pacingInstruction && (
                                    <div>
                                        <Field
                                            name="pacingInstruction0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="pacingInstruction1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="pacingInstruction2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="pacingInstruction3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="pacingInstruction4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="pacingInstruction5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                clearExpectations: !this.state.show
                                                    .clearExpectations,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Clear Expectations</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.clearExpectations
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.clearExpectations && (
                                    <div>
                                        <Field
                                            name="clearExpectations0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="clearExpectations1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="clearExpectations2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="clearExpectations3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="clearExpectations4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="clearExpectations5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                praiseUse: !this.state.show.praiseUse,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Use of Praise</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.praiseUse ? style.open : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.praiseUse && (
                                    <div>
                                        <Field
                                            name="praiseUse0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="praiseUse1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="praiseUse2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="praiseUse3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="praiseUse4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="praiseUse5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                reprimandsUse: !this.state.show.reprimandsUse,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Use of Reprimands</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.reprimandsUse
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.reprimandsUse && (
                                    <div>
                                        <Field
                                            name="reprimandsUse0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="reprimandsUse1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="reprimandsUse2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="reprimandsUse3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="reprimandsUse4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="reprimandsUse5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                smoothTransitions: !this.state.show
                                                    .smoothTransitions,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Smooth Transitions</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.smoothTransitions
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.smoothTransitions && (
                                    <div>
                                        <Field
                                            name="smoothTransitions0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="smoothTransitions1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="smoothTransitions2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="smoothTransitions3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="smoothTransitions4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="smoothTransitions5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                other: !this.state.show.other,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Other</h4>
                                    <span
                                        className={
                                            this.state.show.other ? style.open : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.other && (
                                    <div>
                                        <Field
                                            name="other0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="other1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="other2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="other3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="other4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="other5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>

                            <button
                                className={`btn btn-primary ${style.stickyButton}`}
                                type="submit"
                            >
                                <span style={{ marginRight: '5px' }}>
                                    <Save />
                                </span>
                                Save Values
                            </button>
                        </Form>
                    )}
                />
            </div>
        );
    }
}
