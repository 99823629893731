import React from 'react';
import Tabs from './Tabs';
import CARES from './CARES';
import PBSCC from './PBSCC';

import MessagingDates from './MessagingDates';
import TeacherNudges from './TeacherNudges';

const tabList = [
    { name: 'messagingDates', title: 'Messaging Dates', content: <MessagingDates /> },
    { name: 'teacherNudges', title: 'Teacher Nudges', content: <TeacherNudges /> },
    { name: 'CARES', title: 'CARES', content: <CARES /> },
    {
        name: 'PBSCC',
        title: 'Positive Behavior Supports & Classroom Climate',
        content: <PBSCC />,
    },
];

// tabs load and make query
export default class EditSiteContent extends React.Component {
    componentDidMount = () => {
        window.addEventListener('beforeunload', this.handleUnload);
    };

    handleUnload = event => {
        const message = 'You have unsaved changes';
        event.returnValue = message;
        return message;
    };

    render() {
        return (
            <div className="container">
                <h1>Edit Site Content</h1>
                <Tabs tabList={tabList} saveInHash />
            </div>
        );
    }
}
