import React from 'react';
// import PropTypes from 'prop-types';
import Form from './form.js';
import Loader from 'cccisd-loader';
import Axios from 'cccisd-axios';
import Notification from 'cccisd-notification';
const Boilerplate = window.cccisd.boilerplate;

export default class Component extends React.Component {
    static propTypes = {
        // params: PropTypes.object,
    };

    state = {
        loading: true,
    };

    componentDidMount = async () => {
        // getDataFrom Query
        // passData down to Form
        const response = await Axios.get(Boilerplate.route('app.get.data'));

        if (response) {
            const messagingDates = response.data.data.messagingDates
                ? JSON.parse(response.data.data.messagingDates.data)
                : {};
            this.setState({ data: { ...messagingDates }, loading: false });
        }
    };

    onSubmit = async values => {
        const response = await Axios.post(Boilerplate.route('app.set.data'), {
            data_key: 'messagingDates',
            data: values || {},
        });
        if (response.data.success) {
            Notification({
                message: 'Messaging dates saved.',
                duration: 3000,
            });
        }
    };

    render() {
        return (
            <Loader loading={this.state.loading} removeChildren>
                {this.state.data && <Form onSubmit={this.onSubmit} data={this.state.data} />}
            </Loader>
        );
    }
}
